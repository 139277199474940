// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".markdown_hEZ3m h2{font-size:3rem;font-weight:800;line-height:1}@media (min-width:768px){.markdown_hEZ3m h2{font-size:3.75rem;line-height:1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"markdown": "markdown_hEZ3m"
};
module.exports = ___CSS_LOADER_EXPORT___;
